@charset "utf-8";

@import "variables.scss";


.main-content-bd.at-appconfig{ padding-top: 0; }

.appindex-cnmp{ float: left; text-align: center;
  .thumb{ display: table-cell; width: 160px; height: 68px; vertical-align: middle; background-color: #F5F7F5; font-size: 0; position:relative;
    &:hover .operate-listcell-action{ display:block; }
  }
  .name{ display: block; width: 160px; margin-top: 8px; @include hide-txt; }
}
.appindex-cnmps-shown{ margin: 18px 0 0 0;
  .appindex-cnmp{  height: 115px; margin:0 44px 24px 0;  cursor: pointer;}
}
.operate-row-btns{ float: left;
  .magx-btn-text{ float: left; font-size: 12px; line-height: 32px; margin-right: 20px;}
}
.add-appindex-column{ margin: 16px 0 0 0;
  .column{ margin-bottom: 10px;}
  .magx-textfield{ float: left; margin-right: 20px;
    &.name{ width: 123px; }
    &.link{ width: 293px; }
  }
}
.appindex-cnmps-foradd{ padding: 22px 0 0 0; width: 690px; height:448px; margin: 0 auto;
  .appindex-cnmp{ margin:0 32px 48px 32px; cursor: pointer; }
}

.appindex-edited-entry{ margin-left: 92px;
    .uploaded-imgs-item{ float: left; margin: 0 10px 10px 0; cursor: pointer;}
    .img-box{ width: 160px; height: 80px; background-color: $gray-lightest; }
    .magx-btn-text{ position: absolute; @include align-hvcenter; }
    &.picset{ width:324px;
        .uploaded-imgs-item{ margin: 0 2px 2px 0;}
        .img-box{ height: 60px; }
        &.style2{ position: relative; padding-right: 162px;
            .uploaded-imgs-item:last-child{ position: absolute; right:0; top:0; }
            .uploaded-imgs-item:last-child .img-box{ height: 122px; }
        }
        &.style3{
            .uploaded-imgs-item:first-child .img-box{ height: 122px; }
        }
        &.style4{ position: relative; padding-right: 162px;
          .uploaded-imgs-item:last-child .img-box{}
      }
    }
}
.appindex-picset-styles{
    .style-option{ float: left; width: 56px; text-align: center; margin-right: 70px;}
}
.appindex-cnmp-slider-img-w{ display: inline-block; width: 40px; height: 20px; position: relative; }
.appindex-cnmp-slider-img{ position: absolute; left:0; bottom:0; }

.appconfig-tools{
    .baseinfo{ margin-bottom: 16px; position: relative; }
    .btn-config-tool{ position: absolute; right:30px; top:0; padding-right: 16px;
        .border-top-arr{ right:0; top:7px; border-top-color:$theme-color;
            &.turn-up{ top:0; }
        }
    }
}
.appindex-edited-entry.for-tools{ width: 410px;
     .uploaded-imgs-item{ text-align: center;}
     .uploaded-imgs-item .img-box{ width: 72px; height: 72px; padding: 5px 0;
        &.for-add-btn{ background-color: #fff;}
     }
     .uploaded-imgs-item .uploaded-image{ width: 58px; left:50%; margin-left: -29px;}
    .tool-name{ display: block; width:72px; line-height: 18px; height: 18px; @include hide-txt; margin-top: 8px;}
}
.appconfig-tools.magx-form.common{
    .btn-group{ padding-left: 0; margin: 10px 0 0 94px;
        .magx-btn{ float: left; margin:0 14px 0 0;}
    }
    .magx-textfield{ width: 410px; margin-right: 24px;}
    .magx-btn-text-addnew.for-group-fields{ margin-bottom: 10px;}
}
.appindex-tool-group{
  .desc{ margin-bottom:6px; }
  .appindex-edited-entry.for-tools{ width:auto; margin:0; }
}
